<template>
  <div class="container">
    <poster :poster-list="posterList" :page-path="pagePath" :module-key="moduleKey" :token="token" :time-format="4" />
  </div>
</template>

<script>
import Poster from '../../../components/Poster/Index'

export default {
  name: "XMPoster",
  components: {
    Poster
  },
  data() {
    return {
      token: this.$route.query.token,
      posterList: [
        {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220908093835443.png',
          tempBgImage: null,
          miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220908093817853.png',
          textColor: '#fff',
          tempPoster: '',
          textPoint: [438, 1203],
          wxCode: { width: 188, point: [39, 1125] }
        },
        {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220907114757580.png',
          tempBgImage: null,
          miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220907114734242.png',
          textColor: '#fff',
          tempPoster: '',
          textPoint: [438, 1203],
          wxCode: { width: 188, point: [39, 1125] }
        },
        {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220907182236323.png',
          tempBgImage: null,
          miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220907182222906.png',
          textColor: '#fff',
          tempPoster: '',
          textPoint: [438, 1203],
          wxCode: { width: 188, point: [39, 1125] }
        },
      ],
      pagePath: this.baseHost + '/',
      moduleKey: ''
    }
  },
  created() {
    this.moduleKey = this.token ? '40e3c808-2243-4e9d-95a5-70ed5adfc34a' : 'gzhXMzqj20210910'
  }
}
</script>

<style scoped>

</style>